<template>
  <div v-if="validationErrors">
    <v-alert border="top" color="indigo mt-5" dark>
      <ul class="alert alert-danger">
        <li v-for="(value, key) in validationErrors" :key="key">{{ value }}</li>
      </ul>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "BaseValidation",

  data() {
    return {};
  },
  props: ["errors"],
  computed: {
    validationErrors() {
      let errors = Object.values(this.errors);
      errors = errors.flat();
      return errors;
    },
  },
};
</script>
