import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";
import cs from "vuetify/src/locale/cs.ts";
Vue.use(Vuetify);

const theme = {
  primary: "#e91e63",
  info: "#1e88e5",
  success: "#21c1d6",
  accent: "#fc4b6c",
  default: "#563dea",
};

export default new Vuetify({
  lang: {
    locales: { cs },
    current: "cs",
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
