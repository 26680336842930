import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "home",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: { authOnly: true },
      children: [
        {
          name: "Home",
          path: "home",
          component: () => import("@/views/Home"),
        },
        {
          name: "Publishers",
          path: "publishers",
          component: () => import("@/views/Publishers"),
        },
        {
          name: "WorksConf",
          path: "works-configuration",
          component: () => import("@/views/WorksConf"),
        },
        {
          name: "Report",
          path: "report",
          component: () => import("@/views/Report"),
        },
        {
          name: "Report Karel Svoboda",
          path: "reportCatelog",
          component: () => import("@/views/ReportPerCatalog"),
        },
        {
          name: "Report knihovna",
          path: "reportMec",
          component: () => import("@/views/ReportMec"),
        },
        {
          name: "Setting",
          path: "setting",
          component: () => import("@/views/Setting"),
        },
      ],
    },

    {
      name: "Login",
      path: "/login",
      component: () => import("@/views/authentication/FullLogin"),
      meta: { guestOnly: true },
    },
    {
      name: "TwoFactor",
      path: "/verify",
      component: () => import("@/views/authentication/TwoFactor"),
      meta: { guestOnly: true },
    },
    {
      name: "Error",
      path: "/error",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

function isLoggedIn() {
  return localStorage.getItem("auth");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/home",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
